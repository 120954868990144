import firebase from 'firebase/app';
import 'firebase/firestore';
import { uuid } from 'helpers';
import API from '.';

interface UserMetadata {
  lastSignInTime: string;
  creationTime: string;
  lastRefreshTime?: string | null;
}

interface UserInfo {
  uid: string;
  displayName: string;
  email: string;
  phoneNumber: string;
  photoURL: string;
  providerId: string;
}

interface UserRecord {
  uid: string;
  email?: string;
  emailVerified: boolean;
  displayName?: string;
  phoneNumber?: string;
  photoURL?: string;
  disabled: boolean;
  metadata: UserMetadata;
  providerData: UserInfo[];
  passwordHash?: string;
  passwordSalt?: string;
  customClaims?: {
    [key: string]: any;
  };
  tokensValidAfterTime?: string;
  tenantId?: string | null;
}

interface ListUsersResult {
  users: UserRecord[];
  pageToken?: string;
}

interface AdminAPI {
  /**
   * API endpoint for retriving a list of users
   */
  listUsers: (
    amount: number,
    pageToken: string | null,
    store: boolean
  ) => Promise<ListUsersResult>;
  /**
   * API endpoint for retrieving a user by their UID
   */
  getUser: (uid: string) => Promise<UserRecord>;
  /**
   * API endpoint for creating a user
   */
  createUser: (user: firebase.UserInfo) => Promise<UserRecord>;
  /**
   * API endpoint for updating a user
   */
  updateUser: (uid: string, details: firebase.UserInfo) => Promise<void>;
  /**
   * API endpoint for deleting a user
   */
  deleteUser: (uid: string) => Promise<void>;
  /**
   * API endpoint for setting a student's tokens
   */
  setTokens: (uid: string, tokens: number) => Promise<void>;
  /**
   * API endpoint for adding tokens to a student account
   */
  addTokens: (uid: string, tokens: number) => Promise<void>;
  /**
   * API endpoint for removing tokens from a student's account
   */
  removeTokens: (uid: string, tokens: number) => Promise<void>;

  updateTutorProfile: (
    tutorId: string,
    introduction: string,
    video: string
  ) => Promise<void>;

  updateTutorProfileImage: (tutorId: string, imageURL: string) => Promise<void>;
}

function Admin(self: API): AdminAPI {
  // Generate a unique ID for the new announcement
  const announcementId = uuid();

  const listUsers = async (
    amount: number,
    pageToken: string | null = null,
    store: boolean
  ): Promise<ListUsersResult> => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http<ListUsersResult>(
      `admin/users?amount=${amount}${
        pageToken != null ? `&pageToken=${pageToken}` : ''
      }`,
      'GET',
      {},
      store
    );
  };

  const getUser = async (uid: string): Promise<UserRecord> => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http<UserRecord>(`admin/user/${uid}`, 'GET', {}, true);
  };

  const createUser = async (user: firebase.UserInfo): Promise<UserRecord> => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http<UserRecord>(`admin/users`, 'POST', { data: user }, false);
  };

  const updateUser = async (
    uid: string,
    details: firebase.UserInfo
  ): Promise<void> => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http<void>(
      `admin/user/${uid}`,
      'PUT',
      { data: details },
      false
    );
  };

  const deleteUser = async (uid: string, data: any = {}): Promise<void> => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Make the request and return the response
    return self._http<void>(
      `admin/user/${uid}`,
      'DELETE',
      { data: data },
      false
    );
  };

  const setTokens = async (uid: string, tokens: number): Promise<void> => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    // Await the request and then return
    return firebase.firestore().doc(`students/${uid}`).update({
      'data.lessonTokens': tokens
    });
  };

  const getStudentData = async (uid: string) => {
    // Create a reference to the student collection and query
    // the Firebase UID of the customer
    const studentQuery = await firebase
      .firestore()
      .doc(`students/${uid}`)
      .get();

    return studentQuery;
  };

  const addTokens = async (uid: string, tokens: number): Promise<void> => {
    // Check whether we're the right role before making the request
    self.enforceRole();
    const student = await getStudentData(uid);

    const studentData = student.data();

    const announcementsData = studentData?.data?.announcements ?? [];

    await student.ref.update({
      ...studentData,
      data: {
        ...studentData?.data,
        announcements: [
          ...announcementsData,
          {
            announcementId: announcementId,
            adminPost: true,
            is_viewed: false,
            for: ['student'],
            from: 'e-English Team',
            icon: 'update',
            message: `${tokens} ${tokens > 1 ? 'tokens' : 'token'} ${
              tokens > 1 ? 'have' : 'has'
            } been credited to your account`,
            title: `${tokens} ${tokens > 1 ? 'tokens' : 'token'} credited`,
            when: Date.now()
          }
        ]
      }
    });
    // Await the request and then return
    return firebase
      .firestore()
      .doc(`students/${uid}`)
      .update({
        'data.lessonTokens': firebase.firestore.FieldValue.increment(tokens)
      });
  };

  const removeTokens = async (uid: string, tokens: number): Promise<void> => {
    // Check whether we're the right role before making the request
    self.enforceRole();

    const student = await getStudentData(uid);

    const studentData = student.data();

    const announcementsData = studentData?.data?.announcements ?? [];

    await student.ref.update({
      data: {
        ...studentData?.data,
        announcements: [
          ...announcementsData,
          {
            for: ['student'],
            from: 'e-English Team',
            icon: 'update',
            message: `${tokens} ${tokens > 1 ? 'tokens' : 'token'} ${
              tokens > 1 ? 'have' : 'has'
            } been deducted from your account`,
            title: `${tokens} ${tokens > 1 ? 'tokens' : 'token'} deducted`,
            when: Date.now()
          }
        ]
      }
    });

    // Await the request and then return
    return firebase
      .firestore()
      .doc(`students/${uid}`)
      .update({
        'data.lessonTokens': firebase.firestore.FieldValue.increment(
          tokens * -1
        )
      });
  };

  const updateTutorProfile = async (
    tutorId: string,
    introduction: string,
    video: string
  ): Promise<void> => {
    // Check whether we're the right role before making the request
    self.enforceRole();
    try {
      await firebase.firestore().collection('tutors').doc(tutorId).update({
        'profile.introduction': introduction,
        'profile.video': video
      });
    } catch (error) {
      console.error('Error updating teacher profile:', error);
      throw error;
    }
  };

  const updateTutorProfileImage = async (
    tutorId: string,
    imageURL: string
  ): Promise<void> => {
    // Check whether we're the right role before making the request
    self.enforceRole();
    try {
      await firebase.firestore().collection('tutors').doc(tutorId).update({
        'profile.image': imageURL // Update profile with imageURL
      });
    } catch (error) {
      console.error('Error updating teacher profile image:', error);
      throw error;
    }
  };

  // Return the endpoint functions
  return {
    listUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    setTokens,
    addTokens,
    removeTokens,
    updateTutorProfile,
    updateTutorProfileImage
  };
}

export default Admin;
