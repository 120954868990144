import firebase from 'firebase/app';
import 'firebase/firestore';
import API from '.';

// Live listener class
import Live from './_live';

class Bookings extends Live {
  subscriptions: (() => void)[] = [];
  api: API;
  constructor(api: API) {
    super();

    // Setup a reference to the API
    this.api = api;
  }

  mount(): void {
    // Setup listeners
    if (['student', 'tutor'].includes(this.api.credentials?.role || '')) {
      this.listener(
        'bookingsMyLiveBookings',
        firebase
          .firestore()
          .collectionGroup('bookings')
          .where(
            this.api.credentials?.role || '',
            '==',
            firebase.auth().currentUser?.uid
          )
          .where('when', '>', Date.now() - 1800000) // Account for currently running sessions
          .where('status', 'in', [
            'pending',
            'booked',
            'in-progress',
            'tutor-absent',
            'tutor-cancelled',
            'student-cancelled'
          ])
          .orderBy('when', 'asc')
      );
    }
  }

  /*
    API endpoint for searching for bookings
  */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async searchBookings(query: any): Promise<any> {
    // Determine the user's local timezone dynamically
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Include the userTimezone in the query payload
    const payload = {
      data: query,
      timezone: userTimezone // Add the timezone information to the payload
    };

    return this.api._http('bookings/search', 'POST', { data: payload }, false);
  }

  /*
    API endpoint to retrieve all tutors with booking details
  */
  // async getAllTutors(store = true): Promise<any> {
  //   return this.api._http(`bookings/tutors`, 'GET', {}, store);
  // }
  async getAllTutors(startDate: any, endDate: any, store = true): Promise<any> {
    const queryParams = {
      startDate: startDate.valueOf(),
      endDate: endDate.valueOf()
    };

    try {
      const response = await this.api._http(
        'bookings/tutors',
        'POST',
        {
          data: queryParams
        },
        store
      );

      console.log('API response:', response);
      return response;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  }

  /*
    API endpoint to update previous week income status of tutor
  */
  async updateTutorBookingPaidStatus(
    tutorId: string,
    bookingIds: string,
    status: boolean,
    startDate: string,
    endDate: string
  ): Promise<any> {
    return this.api._http(
      `bookings/updateTutorPaidStatus/${tutorId}`,
      'POST',
      {
        data: {
          bookingIds: bookingIds,
          paidStatus: status,
          startDate: startDate,
          endDate: endDate
        }
      },
      false
    );
  }

  /*
    API endpoint for retriving all CURRENT bookings
  */
  async getMyBookings(store = false): Promise<any> {
    return this.api._firebase(
      'bookingsGetMyBookings',
      async () => {
        return {
          bookings: (
            await firebase
              .firestore()
              .collectionGroup('bookings')
              .where(
                this.api.credentials?.role || '',
                '==',
                firebase.auth().currentUser?.uid
              )
              .where('when', '>', Date.now())
              .orderBy('when', 'desc')
              .limit(5)
              .get()
          ).docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id
            };
          })
        };
      },
      store
    );
  }

  /*
    API endpoint for retrieving booking history
  */
  async getMyBookingHistory(
    startAt: number,
    endAt: number,
    store = false
  ): Promise<any> {
    return this.api._firebase(
      `bookingsGetMyBookingHistory-${startAt}-${endAt}`,
      async () => {
        return {
          bookings: (
            await firebase
              .firestore()
              .collectionGroup('bookings')
              .where(
                this.api.credentials?.role || '',
                '==',
                firebase.auth().currentUser?.uid
              )
              .where('when', '>=', startAt)
              .where('when', '<=', endAt)
              .orderBy('when', 'desc')
              .get()
          ).docs
            .filter((doc) => doc.data().status !== 'pending')
            .map((doc) => {
              const data = doc.data();
              return {
                ...data,
                id: doc.id
              };
            })
        };
      },
      store
    );
  }

  /*
    API endpoint for retrieving a user's completed bookings
  */
  async getCompletedBookings(
    uid: string,
    startAt: number,
    endAt: number,
    store = true,
    onUpdate: (bookings: any) => void
  ): Promise<any> {
    this.api.enforceRole('tutor');

    // Construct Firestore query
    const query = firebase
      .firestore()
      .collectionGroup('bookings')
      .where('tutor', '==', uid)
      .where('status', 'in', ['completed', 'student-absent'])
      .where('when', '>=', startAt)
      .where('when', '<=', endAt);

    // Fetch initial data
    const initialBookings = (await query.get()).docs
      .filter((doc) => doc.data().status !== 'pending')
      .map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id
        };
      });

    // Trigger the onUpdate callback with initial data
    onUpdate(initialBookings);

    // Set up real-time updates using onSnapshot
    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        const updatedBookings = snapshot.docs
          .filter((doc) => doc.data().status !== 'pending')
          .map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id
            };
          });
        onUpdate(updatedBookings); // Send real-time updates to the callback
      },
      (error) => {
        console.error('Error getting real-time updates:', error);
      }
    );

    // Store the unsubscribe function if needed
    if (store) {
      this.subscriptions.push(unsubscribe);
    }

    // Return initial data for immediate use
    return { bookings: initialBookings };
  }
  /*
    API endpoint for getting tutor bookings
  */
  async getTutorSchedule(tutor: string, store = false): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    return this.api._http(`bookings/tutor/${tutor}`, 'GET', {}, store);
  }

  /*
    API endpoint for making a booking
  */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async bookBooking(tutor: string, booking: string, data: any): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    // Make the request and get the response
    const resp = await this.api._http(
      `bookings/tutor/${tutor}/${booking}/book`,
      'PUT',
      { data },
      false
    );

    // Add the booking to getStudentBookings
    const storeBooking = this.api.store.get('firebase-bookingsGetMyBookings');

    // Check whether the store is undefined
    if (storeBooking === undefined) {
      // Update the store
      this.api.store.set('firebase-bookingsGetMyBookings', {
        bookings: [resp.booking]
      });
    } else {
      // Grab the bookings from the store value
      const storeBookings = storeBooking.bookings;

      // Add the new booking the array
      storeBookings.push(resp.booking);

      // Update the store
      this.api.store.set('firebase-bookingsGetMyBookings', {
        ...storeBooking,
        bookings: storeBookings.sort((a: any, b: any) => a.when - b.when) // FIX
      });
    }

    // Run the HTTP function
    return resp;
  }

  /*
    API endpoint for cancelling a booking
  */
  async cancelBooking(tutor: string, booking: string): Promise<any> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    // Run the HTTP function
    const resp = await this.api._http(
      `bookings/tutor/${tutor}/${booking}/cancel`,
      'PUT',
      {},
      false
    );

    // Remove the booking from getStudentBookings
    const storeBooking = this.api.store.get(
      'firebase-bookingsGetStudentBookings'
    );

    // Check whether the store is undefined
    if (storeBooking !== undefined) {
      // Grab the bookings from the store
      const storeBookings = storeBooking.bookings;
      const storeIndex = storeBookings.findIndex(
        (storeItem: any) => storeItem.id === booking
      );

      // If the booking exists in the store, remove it
      if (storeIndex > -1) {
        storeBookings.splice(storeIndex, 1);
      }

      // Update the store
      this.api.store.set('firebase-bookingsGetStudentBookings', {
        ...storeBooking,
        bookings: storeBookings
      });
    }

    // Run the HTTP function
    return resp;
  }

  /*
    API endpoint for cancelling a booking by tutor
  */
  async cancelTutorBooking(
    tutorId: string,
    bookingId: string,
    reason: string
  ): Promise<any> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('tutor');

    // Run the HTTP function
    return this.api._http(
      `bookings/tutor/${tutorId}/${bookingId}/cancelTutor`,
      'PUT',
      {
        data: {
          reason: reason
        }
      },
      false
    );
  }

  /*
    API endpoint for opening a booking slot
  */
  async openBooking(timestamp: number): Promise<any> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('tutor');

    // Run the HTTP function
    return this.api._http(
      `bookings/booking/open/${timestamp}`,
      'POST',
      {},
      false
    );
  }

  // API endpoint for closing a booking slot
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async closeBooking(booking: any): Promise<any> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('tutor');

    // Run the HTTP function
    return this.api._http(`bookings/booking/${booking}`, 'DELETE', {}, false);
  }

  // API endpoint for joining a lesson from a booking
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async joinBooking(booking: any, tutor: string) {
    // Run the HTTP function
    return this.api._http(
      `bookings/booking/${booking}/join`,
      'PUT',
      { data: { tutor } },
      false
    );
  }

  /*
    API endpoint for providing lesson feedback
  */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async submitFeedback(booking: string, feedback: any): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('tutor');

    // Run the HTTP function
    return this.api._http(
      `bookings/booking/${booking}/feedback`,
      'POST',
      { data: feedback },
      false
    );
  }

  /*
    API endpoint for viewing the lesson feedback
  */
  async viewFeedback(tutor: string, booking: string): Promise<any> {
    try {
      // Check whether we're the right role before making the request
      this.api.enforceRole('student');

      // Retrieve the data from Firebase
      const feedbackRef = firebase
        .firestore()
        .doc(`tutors/${tutor}/bookings/${booking}`);
      const feedbackDoc = await feedbackRef.get();

      // Check if the document exists
      if (feedbackDoc.exists) {
        // Get the current feedback data
        const feedbackData = feedbackDoc.data();

        // Check if isViewedOnce is not already true
        if (!feedbackData?.isViewedOnce) {
          // Update the isViewedOnce field to true
          await feedbackRef.update({
            isViewedOnce: true
          });
        }

        // Return the feedback data
        return feedbackData?.behaviour;
      } else {
        // Handle the case where the document does not exist
        console.warn('Feedback document not found.');
        return null; // or throw an error, depending on your use case
      }
    } catch (error) {
      // Handle errors, log them, or throw a specific error based on your needs
      console.error('Error retrieving or updating feedback:', error);
      throw error;
    }
  }

  /*
    API endpoint for getting behavioural feedback
  */
  async getFeedbackBehaviour(
    tutor: string,
    booking: string,
    store = true
  ): Promise<any> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('tutor');

    // Retrieve the data from firebase
    return this.api._firebase(
      `bookingsGetFeedbackBehaviour-${booking}`,
      async () => {
        return (
          await firebase
            .firestore()
            .doc(`tutors/${tutor}/feedback/${booking}`)
            .get()
        ).data()?.behaviour;
      },
      store
    );
  }

  /*
    API endpoint for getting behavioural feedback history
  */
  async getFeedbackHistory(student: string, store = true): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('tutor');

    // Retrieve the data from firebase
    return this.api._firebase(
      `bookingsGetFeedbackHistory-${student}`,
      async () => {
        return (
          await firebase
            .firestore()
            .collectionGroup('bookings')
            .where('student', '==', student)
            .limit(5)
            .get()
        ).docs
          .filter((doc) => doc.data().feedback)
          .map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
      },
      store
    );
  }

  // --- ADMIN ENDPOINTS ---
  /*
    ADMIN API endpoint for retrieving a user's bookings
  */
  async adminGetUserBookings(
    uid: string,
    role: 'student' | 'tutor',
    startAt: number,
    endAt: number,
    store = true
  ): Promise<any> {
    // Check whether we're the right role before making the request
    this.api.enforceRole();

    // Retrieve the data from firebase
    return this.api._firebase(
      `bookingsAdminGetUserBookings-${uid}-${role}-${startAt}-${endAt}`,
      async () => {
        return (
          await firebase
            .firestore()
            .collectionGroup('bookings')
            .where(role, '==', uid)
            .where('when', '>=', startAt)
            .where('when', '<=', endAt)
            .orderBy('when', 'desc')
            .get()
        ).docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id
          };
        });
      },
      store
    );
  }

  async submitSchedule(
    schedule: { day: string; time: string }[],
    studentId: string // Add studentId as a parameter
  ): Promise<void> {
    this.api.enforceRole('student');

    console.log('schedule', schedule);
    const studentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Make the request and get the response
    return this.api._http(
      `bookings/schedule`, // Endpoint to handle schedule submission
      'POST',
      { data: { schedule, studentId, studentTimezone } },
      false
    );
  }

  async adminGetBookingSchedules(
    startDate: number,
    endDate: number,
    store = true
  ): Promise<any> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('admin');

    // Construct query parameters
    const queryParams = {
      startDate: startDate,
      endDate: endDate
    };

    try {
      // Make the HTTP request
      return await this.api._http(
        'bookings/adminGetBookingSchedules',
        'POST',
        { data: queryParams },
        store
      );
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  }
}

export default Bookings;
