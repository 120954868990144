import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

// Material UI imports
import {
  Tooltip,
  Typography,
  Button,
  Grid,
  Avatar,
  Popover,
  withStyles,
  Chip
} from '@material-ui/core';

// Material UI Icons
import {
  AssignmentRounded as LessonIcon,
  FeedbackRounded as FeedbackIcon,
  CallRounded as CallIcon,
  PublishRounded as SubmitIcon,
  CancelRounded as CancelIcon,
  WarningRounded as WarningIcon
} from '@mui/icons-material';

// Shared helpers
import { getInitials, prettyBookingStatus } from 'helpers';

// DateTime helpers
import { DateTime } from 'luxon';

// Call timeline dialog
import { CallTimelineDialog, BookingInfo, FeedbackInfo } from 'components';

// Style
import styles from './styles';
import moment from 'moment';

function BookingCard(props) {
  // Grab some values of of the component's properties
  const {
    classes,
    auth,
    booking,
    history,
    onCancel,
    onSubmitFeedback,
    onViewFeedback
  } = props;

  // Initialize a relative-time updating state
  const [relative, updateRelative] = useState(
    DateTime.fromMillis(booking.when || 0).toRelative()
  );

  // Initialize a popover state
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  // Initialize a feedback popover state
  const [feedbackAnchor, setFeedbackAnchor] = useState(null);

  // Initialize a call timeline dialog reference
  const callTimelineDialogRef = useRef(null);

  // Create a timer to update the relative time
  useEffect(() => {
    const relativeInterval = setInterval(() => {
      updateRelative(DateTime.fromMillis(booking.when || 0).toRelative());
    }, 1000);

    return () => {
      clearInterval(relativeInterval);
    };
  });

  // Get the opposite role
  const roleSwitch = auth.role === 'student' ? 'tutor' : 'student';

  // Get the correct profile information according to the role
  const { name, image } = booking.profile[roleSwitch];

  const isBookingAlive = !moment(booking.when)
    .subtract(12, 'hours')
    .isAfter(moment());

  return (
    <>
      <Popover
        id={popoverAnchor ? 'simple-popover' : undefined}
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={() => {
          setPopoverAnchor(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <BookingInfo info={booking.info} booking={booking} />
      </Popover>
      <Popover
        id={feedbackAnchor ? 'simple-feedback-popover' : undefined}
        open={Boolean(feedbackAnchor)}
        anchorEl={feedbackAnchor}
        onClose={() => {
          setFeedbackAnchor(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <FeedbackInfo
          student={booking.student}
          onViewFeedback={(bookingFeedback) => onViewFeedback(bookingFeedback)}
        />
      </Popover>
      <CallTimelineDialog ref={callTimelineDialogRef} />
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid
            item
            className={classes.avatarWrapper}
            xs={12}
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto">
            {auth.role === 'student' ? (
              <Tooltip title="Goto Profile" aria-label="Go to profile">
                <Link to={`/student/profile/${booking.tutor}`}>
                  <Avatar src={image} alt={name} className={classes.avatar}>
                    <Typography variant="h3" style={{ color: 'white' }}>
                      {getInitials(name || '')}
                    </Typography>
                  </Avatar>
                </Link>
              </Tooltip>
            ) : (
              <Tooltip
                title="View Feedback History"
                aria-label="View Feedback History"
                onClick={(event) => setFeedbackAnchor(event.currentTarget)}>
                <Avatar src={image} alt={name} className={classes.avatar}>
                  <Typography variant="h3" style={{ color: 'white' }}>
                    {getInitials(name || '')}
                  </Typography>
                </Avatar>
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            className={classes.info}
            xs={12}
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto">
            <Typography variant="h3">{name}</Typography>
            {auth.role === 'tutor' &&
              !booking.feedback &&
              booking.status !== 'student-absent' &&
              booking.when + 86400000 < Date.now() && (
                <div className={classes.inelegible}>
                  <Chip
                    icon={<WarningIcon />}
                    label={`Ineligible for payment ${
                      booking.status === 'tutor-absent'
                        ? '(teacher absent)'
                        : ''
                    }`}
                  />
                </div>
              )}
            <div className={classes.rebooking}>
              <Chip
                size="small"
                label={prettyBookingStatus(booking.status, auth.role)}
                className={
                  ['student-absent', 'tutor-absent'].includes(booking.status)
                    ? classes.rebookBad
                    : booking.status === 'tutor-cancelled' ||
                      booking.status === 'student-cancelled'
                    ? classes.cancelledBook
                    : classes.rebookNormal
                }
              />
              <Typography color="textSecondary">
                {`${DateTime.fromMillis(booking.when || 0).toFormat(
                  'd LLL h:mm a'
                )}, ${relative}`}
              </Typography>
            </div>
            {auth.role === 'student' && ((!history && onCancel) || history) && (
              <div className={classes.actions}>
                <Button
                  size="small"
                  className={classes.gutterRight}
                  variant="contained"
                  onClick={(event) => setPopoverAnchor(event.currentTarget)}
                  startIcon={<LessonIcon />}>
                  view details
                </Button>
                {!history && onCancel && (
                  <Tooltip
                    title={
                      booking.status === 'tutor-cancelled'
                        ? `Teacher has cancelled this lesson`
                        : isBookingAlive
                        ? `You can't cancel within 12 hours of commencement`
                        : ''
                    }>
                    <div>
                      <Button
                        size="small"
                        disabled={isBookingAlive}
                        startIcon={
                          booking.status === 'tutor-cancelled' ? (
                            <></>
                          ) : (
                            <CancelIcon />
                          )
                        }
                        variant="contained"
                        onClick={(e) => {
                          booking.status === 'tutor-cancelled'
                            ? e.preventDefault()
                            : onCancel();
                        }}
                        className={
                          booking.status === 'tutor-cancelled'
                            ? classes.cancelledBook
                            : classes.cancel
                        }>
                        {booking.status === 'tutor-cancelled'
                          ? `cancelled`
                          : `cancel lesson`}
                      </Button>
                    </div>
                  </Tooltip>
                )}
                {history && (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={
                      !booking.feedback || booking.status === 'student-absent'
                    }
                    onClick={() => onViewFeedback(booking)}
                    startIcon={<FeedbackIcon />}>
                    view feedback
                  </Button>
                )}
              </div>
            )}
            {auth.role === 'tutor' && (
              <div className={classes.actions}>
                <Button
                  size="small"
                  className={classes.gutterRight}
                  variant="contained"
                  onClick={(event) => setPopoverAnchor(event.currentTarget)}
                  startIcon={<LessonIcon />}>
                  view details
                </Button>
                {!history && onCancel && (
                  <Tooltip
                    title={
                      isBookingAlive
                        ? `You can't cancel within 12 hours of commencement`
                        : 'Cancel your session by stating your reason'
                    }>
                    <Button
                      size="small"
                      disabled={isBookingAlive}
                      startIcon={
                        booking.status === 'tutor-cancelled' ? (
                          <></>
                        ) : (
                          <CancelIcon />
                        )
                      }
                      variant="contained"
                      onClick={(e) => {
                        booking.status === 'tutor-cancelled'
                          ? e.preventDefault()
                          : onCancel();
                      }}
                      className={
                        booking.status === 'tutor-cancelled'
                          ? classes.cancelledBook
                          : classes.cancel
                      }>
                      {booking.status === 'tutor-cancelled'
                        ? `cancelled`
                        : `cancel lesson`}
                    </Button>
                  </Tooltip>
                )}
                {booking.logs && (
                  <Button
                    size="small"
                    className={`${classes.gutterRight} ${classes.hideOnSmall}`}
                    variant="outlined"
                    color="primary"
                    onClick={() => callTimelineDialogRef.current.open(booking)}
                    startIcon={<CallIcon />}>
                    call log
                  </Button>
                )}
                {booking.feedback ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => onViewFeedback(booking)}
                    startIcon={<FeedbackIcon />}>
                    view feedback
                  </Button>
                ) : (
                  booking.status === 'pending-feedback' && (
                    <Button
                      size="small"
                      disabled={
                        Date.now() <= booking.when ||
                        booking.when + 86400000 < Date.now()
                      }
                      variant="contained"
                      color="primary"
                      onClick={onSubmitFeedback}
                      startIcon={<SubmitIcon />}>
                      submit feedback
                    </Button>
                  )
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withStyles(styles)(BookingCard);
